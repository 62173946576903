import React from 'react';
import "./Footer_plataforma.css";
import { FaFacebook, FaInstagram, FaEnvelope ,FaYoutube,FaLinkedin} from 'react-icons/fa'; // Import the icons

function Footer_plataforma() {
  const currentYear = new Date().getFullYear(); // Get the current year
  return (
    <div className="footer_plataforma">
        <div className="footer_socials_plataforma">
          <div className="social-icons0_plataforma">
            <a href="https://www.facebook.com/casapropriahub/" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://instagram.com/casapropriahub?igshid=OGQ5ZDc2ODk2ZA%3D%3D$&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com/company/casa-pr%C3%B3pria/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
            <a href="https://www.youtube.com/@casapropriahub"  target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a>
            <a href="mailto:ola@casa-propria.co.mz">
              <FaEnvelope />
            </a>
          </div>
        </div>
       {/* <div className="copyright">
          <footer className="copyright">
            <p>&copy; {currentYear}  Casa Própria. Todos Direitos Reservados.</p>
          </footer>
        </div>*/}
    </div>
  );
}

export default Footer_plataforma;
