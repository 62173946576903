const data_lojas = [

  {
       id: "01LBL",
       name: "Projecto 01LBL",
       imageUri:  require("../projectos/01LBL/Casa-propria-moderna-negocio-loja-barraca-laje-betao-01.webp"),
       title: "Loja “Boutique”",
       title1:"LAJE",
       title2:"S01",
       title3:'',
       price: "20,000 Mt",
       buttonText: "Veja mais",
       link: "/projectos/01LBC",
      
       imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
       imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
       imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
       imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
       imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
       buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
       buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
       hoverStyles: {
          opacity: 1,
          background: 'rgba(0, 0, 0, 0.6)',
          marginTop: '0%',
          marginLeft: '0%',
          height: '${(52 / 9) * 16}vw',
          // Add other unique hover styles for this image
        }
 
     },
 {
     id: "01LBC",
     name: "Projecto 01LBC",
     imageUri:  require("../projectos/01LBC/Casa-propria-moderna-negocio-loja-barraca-chapa-01.webp"),
      title: "Loja “Boutique”",
      title1:"CHAPA",
      title2:"S01",
      title3:'',
      price: "20,000 Mt",
     description: "Pensada para uma família moderna, esta casa é ideal para quem busca o espaço de lazer na parte frontal do terreno. Os quartos estão perfilados na parte traseira deixando os espaços sociais (sala e cozinha) na parte frontal intencionalmente abertos à área de lazer promovendo continuidade entre os ambientes internos e externos, bastante practico em dias de eventos. O layout geminado na parte traseira, revela-se em um volume simples marcadamente horizontal que se ajusta à escala humana nos espaços exteriores de chegada, de convívio e de serviço tornando-nos mais aconchegantes. || Como todos os projectos da Série 01, este está pensado para ser construído em fases podendo começar com um núcleo (T0), T1, T2 ou completo T3 com um quarto suite. Para acomodar o trabalho remoto ou os TPC's, criámos um cantinho que lhe proporcionará o conforto do lar enquanto trabalha ou estuda.  ||   Esta variante apresenta a cobertura em laje de betão armado, podendo ser maciça ou aligeirada. Pensada para responder passivamente à natureza foram desenhados sistemas, de ventilação cruzada e em chaminé estimulando o ar quente a abandonar o edifício, sistema de recolha de águas pluviais que após tratamento poderão ser posteriormente usadas nas diversas actividades da casa e um sistema de painéis solares que contribuirá para a redução da sua conta de energia.    ||   No decurso da obra, tanto o layout, o número de pisos ou o tipo de cobertura, poderão ser alterados para qualquer das variantes da Série 01 e, em função da necessidade da sua família, ao projecto da casa principal podem-se adicionar Anexo, Loja ou Muro de vedação. Se este projecto for a tua cara mas não satisfazer todas as suas exigências, deixe-nos saber que a nossa equipa terá muito gosto em adaptá-lo para que se pareça com a casa dos teus sonhos e assim garantir o conforto para si e sua família.",
     buttonText: "Veja mais",
     link: "/projectos/01LBC",
    
     imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
     imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
     imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
     imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
     imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
     buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
     buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
     hoverStyles: {
        opacity: 1,
        background: 'rgba(0, 0, 0, 0.6)',
        marginTop: '0%',
        marginLeft: '0%',
        height: '${(52 / 9) * 16}vw',
        // Add other unique hover styles for this image
      }
   },
 
   {
 
    id: "01LTC",
    name: "Projecto 01LTC",
    imageUri:  require("../projectos/01LTC/Casa-propria-moderna-negocio-loja-barraca-chapa-02.webp"),
    title: "Loja “Take away”",
    title1:"CHAPA",
    title2:"S01",
    title3:'',
    price: "20,000 Mt",
     buttonText: "Veja mais",
    link: "/projectos/01LTC",

    imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
    imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
    imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
    imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
    imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
    buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
    buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
    hoverStyles: {
       opacity: 1,
       background: 'rgba(0, 0, 0, 0.6)',
       marginTop: '0%',
       marginLeft: '0%',
       height: '${(52 / 9) * 16}vw',
       // Add other unique hover styles for this image
     }
  },
   {
       id: "01LTL",
           name: "Projecto 01LTL",
           imageUri:  require("../projectos/01LTL/Casa-propria-moderna-negocio-loja-barraca-laje-betao-01.webp"),
           title: "Loja “Take away”",
       title1:"LAJE",
       title2:"S01",
       title3:'',
       price: "20,000 Mt",
           buttonText: "Veja mais",
           link: "/projectos/01LTL",
         
      imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
      imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
      hoverStyles: {
         opacity: 1,
         background: 'rgba(0, 0, 0, 0.6)',
         marginTop: '0%',
         marginLeft: '0%',
         height: '${(52 / 9) * 16}vw',
         // Add other unique hover styles for this image
       }
 
     },
 
   {
     id: "01LML",
     name: "Projecto 01LML",
     imageUri:  require("../projectos/01LML/Casa-propria-moderna-negocio-loja-barraca-laje-betao-01.webp"),
     title: "Loja “Mercearia”",
     title1:"LAJE",
     title2:"S01",
     title3:'',
     price: "20,000 Mt",
      buttonText: "Veja mais",
     link: "/projectos/01LML",
     
     imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
     imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
     imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
     imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
     imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
     buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
     buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
     hoverStyles: {
        opacity: 1,
        background: 'rgba(0, 0, 0, 0.6)',
        marginTop: '0%',
        marginLeft: '0%',
        height: '${(52 / 9) * 16}vw',
        // Add other unique hover styles for this image
      }
 
       },
 
   {
     id: "01LMC",
     name: "Projecto 01LMC",
     imageUri:  require("../projectos/01LMC/Casa-propria-moderna-negocio-loja-barraca-chapa-01.webp"),
    title: "Loja “Mercearia”",
    title1:"CHAPA",
    title2:"S01",
    title3:'',
    price: "20,000 Mt",
     buttonText: "Veja mais",
     link: "/projectos/01LMC",
     
     imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
     imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
     imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
     imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
     imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
     buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
     buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
     hoverStyles: {
        opacity: 1,
        background: 'rgba(0, 0, 0, 0.6)',
        marginTop: '0%',
        marginLeft: '0%',
        height: '${(52 / 9) * 16}vw',
        // Add other unique hover styles for this image
      }
   },
   ]
 export default data_lojas;
 