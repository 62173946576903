import React, { useState, useEffect } from 'react';
import Navbar_black from '../components/Navbar_black';
import Logo from '../components/Logo';
import "./Contacts.css";
import "./DecretoDetails.css";
import PublicityDisplay from './PublicityDisplay'; 
import data_decretos from "./data_decretos.js"


const DecretoDetails = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const [showNavbar, setShowNavbar] = useState(false);
  
    const handleMouseEnter = () => {
      setShowNavbar(true);
    };
  
    const handleMouseLeave = () => {
      setShowNavbar(false);
    };
  
    useEffect(() => {
      const setElementDimensions = () => {
        const rightCard = document.querySelector('.contact_right-container_ls');
        if (rightCard) {
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;
  
          const adjustedWidth = windowWidth - (windowWidth * 0.1); // Adjust width by 10%
          const adjustedHeight = windowHeight - (windowHeight * 0.1); // Adjust height by 10%
  
          rightCard.style.width = `${adjustedWidth}px`;
          rightCard.style.height = `${adjustedHeight}px`;
        }
      };
  
      // Initial call to set dimensions on component mount
      setElementDimensions();
  
      // Event listener for window resize to update dimensions
      window.addEventListener('resize', setElementDimensions);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', setElementDimensions);
      };
    }, []);

    // Function to extract a readable name from the URI
    const extractDocumentName = (uri) => {
        const namePart = uri.split('/').pop(); // Get the last part of the URI
        const withoutExtension = namePart.replace(/\.\w+$/, ''); // Remove the file extension
        return withoutExtension.replace(/_/g, ' '); // Replace underscores with spaces for readability
    };

    // Helper function to title-case a string except for certain words
    const toTitleCase = (str) => {
        const lowerCaseStr = str.toLowerCase();
        return lowerCaseStr.split(' ').map((word, index) => {
            // Always capitalize the first word and any word not in the exception list
            if (index === 0 || !['de', 'da', 'do', 'dos', 'e'].includes(word)) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word;
        }).join(' ');
    };

    // Updated formatDocumentName function
    const formatDocumentName = (uri) => {
        // Extract the filename from the URI
        const fileName = uri.split('/').pop();
        // Remove the hash part
        const cleanName = fileName.replace(/\.\w{20,}\.\w+$/, '');
        // Replace '+' and underscores with spaces, remove file extension for display
        const nameWithoutExtension = cleanName.replace(/\+/g, ' ').replace(/_/g, ' ').replace(/\.\w+$/, '');

        return toTitleCase(nameWithoutExtension);
    };

    return (
      <div className='container_about' > {/* onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>>*/}
        {/*showNavbar && <Navbar_black />*/}
        <Navbar_black />
        <Logo />
        <div className="contactos_text">
          <h2>DECRETOS</h2>
        </div>
        
        <div className="decreto-info">

        <div className="documents-list-decreto">
                    {data_decretos.map((decreto, index) => (
                        Object.entries(decreto).filter(([key, _]) => key.startsWith('imageUri')).map(([key, uri], idx) => (
                            <div key={idx} className="document-link-decreto">
                                {/* Display the link with the formatted document name */}
                                {/*<a href={uri} download>
                                    {formatDocumentName(uri)}
                        </a>*/}
                                <a href={uri} download>
                                  <span>• {formatDocumentName(uri) }</span> 
                                  {/*<span role="img" aria-label="Microsoft Word"  className="icon-space">📄</span> */}
                                </a>
                            </div>
                        ))
                    ))}
                </div>

        </div> 
     

        <div className="copyright_decreto">
          <footer className="copyright_decreto">
            <p>&copy; {currentYear} Casa Própria. Todos Direitos Reservados.</p>
          </footer>
        </div>
      </div>
    );


    
};
export default DecretoDetails;
