import React, { useState, useEffect } from 'react';
import Navbar_black from '../components/Navbar_black';
import Logo from '../components/Logo';
import "./Reservacao.css";

import ReservacaoForm from './ReservacaoForm';
import imageFile from '../projectos/Reserva/Casa_propria_Instrucoes.jpg';
import pdfFile from '../projectos/Reserva/Casa_propria_Instrucoes_Informacoes.pdf'; // Import the PDF file


const Reserva = () => {
  const handleClick = () => {
    window.open(pdfFile, '_blank');
  };
  const [showNavbar, setShowNavbar] = useState(false);

  const handleMouseEnter = () => {
    setShowNavbar(true);
  };

  const handleMouseLeave = () => {
    setShowNavbar(false);
  };

  useEffect(() => {
    const setElementDimensions = () => {
      const leftColumn = document.querySelector('.rleft-column-a');
      const rightColumn = document.querySelector('.rright-column-a');

      if (leftColumn && rightColumn) {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        const adjustedWidth = windowWidth - (windowWidth * 0.1); // Adjust width by 10%
        const adjustedHeight = windowHeight - (windowHeight * 0.1); // Adjust height by 10%

        leftColumn.style.width = `${adjustedWidth / 2}px`; // Adjust width of left column
        leftColumn.style.height = `${adjustedHeight}px`; // Adjust height of left column

        rightColumn.style.width = `${adjustedWidth / 2}px`; // Adjust width of right column
        rightColumn.style.height = `${adjustedHeight}px`; // Adjust height of right column
      }
    };

    setElementDimensions();

    window.addEventListener('resize', setElementDimensions);

    return () => {
      window.removeEventListener('resize', setElementDimensions);
    };
  }, []);

  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <div className='container_reserva' > {/*onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      showNavbar && <Navbar_black />*/}
      <Navbar_black />
      <Logo />
      <div className="reserva_text">
        <h2>RESERVA</h2>
      </div>
      <div className="reserva_container_pub">
        {/* <PublicityDisplay />*/}
      </div>
      <div className="reserva_right-container">
        <div className="rright-column-a">
          <p className="reserva_p1"> FAÇA AQUI</p>
          <div>
            <ReservacaoForm />
          </div>
        </div>
        <div className="reserva_left-column-a">
            {/*} <img
                src={imageFile}
                alt="reservaImage"
              />*/}
          <a href={pdfFile} target="_blank" rel="noopener noreferrer" >
             <img src={imageFile} alt="PDF Image" onClick={handleClick} />
          </a>
        </div>
      </div>
      <div className="copyright_r">
        <footer className="copyright_r">
          <p>&copy; {currentYear}  Casa Própria. Todos Direitos Reservados.</p>
        </footer>
      </div>
    </div>
  );
};

export default Reserva;
