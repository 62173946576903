const data_decretos = [ 
    {
        id: "01",
        name: "Decreto",
        imageUri:  require("../projectos/CONTEUDO/DECRETOS/Resolução n.º 76_AM_2017 - BR_136_III_SÉRIE_2018.pdf"),
        imageUri2:  require("../projectos/CONTEUDO/DECRETOS/Resolução n.º 115_AM_2003 de 30 de Outubro - Guião de Gestão de Terras Urbanas HRes.pdf"),
        link: "/projectos/CONTEUDO/DECRETOS",
        },
    
         
      ]
    export default data_decretos;
    