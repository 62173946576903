/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://btdtr7mg4fd7xignpjegxkm4ne.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hbzam5ozeja3fdpa6sozp53fe4",
    "aws_content_delivery_bucket": "casapropria-20240115153415-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-3",
    "aws_content_delivery_url": "https://d2w03piyxx9n2c.cloudfront.net",
    "aws_cognito_identity_pool_id": "eu-west-3:264b252b-8bad-4c32-a5ea-323cf4327a28",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_hA9Wd3ufc",
    "aws_user_pools_web_client_id": "720hj7auk56alnih20a6tiqm0n",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "minutascasapropria150131-dev",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
