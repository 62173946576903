const data = [
{
    id: "1",
    imageUri:  require("../projectos/HOMEPAGE/01-Habitacao-casa-propria-moderna-geminada-evolutiva-chapa-06.webp"),
    link: "/projectos/01N",
  },
 {
      id: "2",
      imageUri: require("../projectos/HOMEPAGE/02-Habitacao-casa-propria-moderna-anexo-laje-betao.webp"),
      link: "/projectos/01N",
    },
  {
    id: "3",
    imageUri: require("../projectos/HOMEPAGE/03-Habitacao-casa-propria-moderna-2pisos-laje-betao-15.webp"),
    link: "/projectos/01N",
  },
  {
    id: "4",
    imageUri: require("../projectos/HOMEPAGE/04-Habitacao-casa-propria-moderna-geminada-chapa.webp"),
    link: "/projectos/01N",
  },
  {
    id: "5",
    imageUri: require("../projectos/HOMEPAGE/05-Habitacao-casa-propria-moderna-geminada-chapa-02.webp"),
    link: "/projectos/01N",
  },
  {
    id: "6",
    imageUri: require("../projectos/HOMEPAGE/06-Habitacao-casa-propria-moderna-isolada-chapa.webp"),
    link: "/projectos/01N",
  },
  {
    id: "7",
    imageUri: require("../projectos/HOMEPAGE/07-Habitacao-casa-propria-moderna-geminada-laje-betao-01.webp"),
    link: "/projectos/01N",
  },
  {
    id: "8",
    imageUri: require("../projectos/HOMEPAGE/08-Habitacao-casa-propria-moderna-geminada-chapa-11.webp"),
    link: "/projectos/01N",
  },
  {
    id: "9",
    imageUri: require("../projectos/HOMEPAGE/09-Habitacao-casa-propria-moderna-geminada-evolutiva-laje-01.webp"),
    link: "/projectos/01N",
  },
  {
    id: "10",
    imageUri: require("../projectos/HOMEPAGE/10-Habitacao-casa-propria-moderna-geminada-chapa-01.webp"),
    link: "/projectos/01N",
  },
  {
    id: "11",
    imageUri: require("../projectos/HOMEPAGE/11-Habitacao-casa-propria-moderna-geminada-chapa.webp"),
    link: "/projectos/01N",
  },
  {
    id: "12",
    imageUri: require("../projectos/HOMEPAGE/12-Casa-propria-moderna-negocio-loja-barraca-chapa-03.webp"),
    link: "/projectos/01N",
  },


  ]
export default data;
