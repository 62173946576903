import React from 'react';
import {ChakraProvider} from '@chakra-ui/react'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Amplify} from 'aws-amplify'; 
import config from './aws-exports'; 

import awsconfig from './aws-exports'; 

 Amplify.configure(awsconfig); 

//Amplify.configure(config);

ReactDOM.render(
  <ChakraProvider>

        <App />

  </ChakraProvider>,
   document.getElementById('root') 
);
