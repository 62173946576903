import React, { useState, useEffect } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import data_casas from "./data_casas";
import data_anexos from "./data_anexos";
import data_lojas from "./data_lojas";
import data_casas_small from "./data_casas_small";
import data_anexos_small from "./data_anexos_small";
import data_lojas_small from "./data_lojas_small";

import data_casas_mac from "./data_casas_mac";
import data_anexos_mac from "./data_anexos_mac";
import data_lojas_mac from "./data_lojas_mac";

import Navbar_black from '../components/Navbar_black';
import Logo from '../components/Logo';

import PublicityDisplay from './PublicityDisplay';
import "./Contacts.css";
import "./Projects.css";


const ProjectSection = ({ sectionId, imageData }) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  return (
    <div id={sectionId}>
      <div className="project-card">
        <div className="card-image-container">
          {imageData.map((item, index) => (
            <a key={item.id} href={item.link} >
              <div
                className="image-container"
                style={{ position: 'relative', ...(item.imageSize || item.rectangle) }}
                onMouseEnter={() => setHoveredItem(item.id)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <img src={item.imageUri} alt={`Image ${index}`} style={{ height: '100%', width: '100%' }} />
                
                {hoveredItem === item.id && item.imageUri && (
                  <div className="text-overlay" style={{ position: 'absolute', ...item.hoverStyles }}>
                  </div>
                )}
                

              </div>

              <div  className="texto">
                
                        <div style={{ position: 'relative'}}>  
                          <h3 style={item.imageSizeTitle2}>{item.title2}</h3>
                          <h3 style={item.imageSizeTitle1}>{item.title1}</h3>
                          <h3 style={item.imageSizeTitle}>{item.title}</h3>
                          <h3 style={item.imageSizeTitle3}>{item.title3}</h3>
                          <h4 style={item.buttonprice}>{item.price}</h4>

                        <a
                            href={item.link} 
                            rel="noopener noreferrer"
                            className="button-link"
                            style={item.buttonlink}
                          >
                            {item.buttonText}
                          </a> 
                        
                        </div>
                </div>
              
                

        
                
            </a>
          ))}
          
        </div>
      </div>
    </div>
  );
};


const Projectos = () => {
const [activeTab, setActiveTab] = useState('casas'); 
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [dataToUse, setDataToUse] = useState(data_casas);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

  let newDataToUse;
  if (activeTab === 'casas') {

    if (screenSize < 1024) {
      newDataToUse = data_casas_small;
    } else if (screenSize >= 1024 && screenSize < 1599) {
      newDataToUse = data_casas_mac;
    } else if (screenSize >= 1600  && screenSize < 1823 )  {
      newDataToUse = data_casas_small;
    } else {
      newDataToUse = data_casas; 
    }
  } 
  else if (activeTab === 'anexos') {
    if (screenSize < 1024) {
      newDataToUse = data_anexos_small;
    } else if (screenSize >= 1024 && screenSize < 1599){
      newDataToUse = data_anexos_mac;
    } else if (screenSize >= 1600  && screenSize < 1823 )  {
      newDataToUse = data_anexos;
    } else {
      newDataToUse = data_anexos; 
    }
  }
  else if (activeTab === 'lojas') 
  {
    if (screenSize < 1024) {
      newDataToUse = data_lojas_small;
    } else if (screenSize >= 1024 && screenSize < 1599) {
      newDataToUse = data_lojas_mac;
    } else if (screenSize >= 1600  && screenSize < 1823 ) {
      newDataToUse = data_lojas_mac;
    } else {
      newDataToUse = data_lojas;  
    }
  }
    setDataToUse(newDataToUse);
  }, [screenSize, activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    scroll.scrollToTop({
      duration: 0, // You can adjust the duration as needed
    });
  };

  const currentYear = new Date().getFullYear(); 
  return (

<div className='container_project_p' > 
   <Navbar_black />
   <Logo />

   <div className = "contactos_text_rr">
         <div className="tab-container">
                  <div onClick={() => handleTabClick('casas')} className={activeTab === 'casas' ? 'active-tab' : 'tab'}>
                    Casas
                  </div>
                  <div onClick={() => handleTabClick('anexos')} className={activeTab === 'anexos' ? 'active-tab' : 'tab'}>
                    Anexos
                  </div>
                  <div onClick={() => handleTabClick('lojas')} className={activeTab === 'lojas' ? 'active-tab' : 'tab'}>
                    Lojas
                  </div>
         </div>
   </div>

   <div className="contact_container_pub_p">
        {/*<PublicityDisplay />*/}
   </div>
   <div className="contact_right-container_p">
        <ProjectSection sectionId="section1" imageData={dataToUse} />

 

   </div>


   <div className="copyright_pro">
        <footer className="copyright_pro">
          <p>&copy; {currentYear}  Casa Própria. Todos Direitos Reservados.</p>
        </footer>
  </div>

</div>

  );
};

export default Projectos;


