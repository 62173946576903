import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { createReserva } from '../../src/graphql/mutations';
import { generateClient } from "aws-amplify/api";
import './ReservacaoForm.css';

const client = generateClient();

function ReservacaoForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const { title } = location.state || {};
  const toast = useToast();

  const [formState, setFormState] = useState({
    name: '',
    phone: '',
    project: title || '' ,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };


  useEffect(() => {
    // Check if title exists and project is empty, then redirect to '/projectos'
    if (!title && formState.project.trim() !== '' && formState.project !== title) {
      toast({
        title: 'Redireccionamento...',
        description: 'Escolha o seu projeto.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
      navigate('/projectos');
    }
  }, [formState.project, title, navigate, toast]);
    

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, phone, project } = formState;

    if (name.trim() && phone.trim() && project.trim()) {
      try {
        await client.graphql({
          query: createReserva,
          variables: {
            input: {
              name,
              phone,
              project,
            }
          }
        });
        toast({
          title: 'Successo',
          position: 'top-right',
          description: 'Reserva enviada com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setFormState({ name: '', phone: '', project: '' });
      } catch (error) {
        toast({
          title: 'Error',
          position: 'top-right',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'Incomplete Form',
        position: 'top-right',
        description: 'Por favor, preencha todos os campos!',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label className="label" htmlFor="project">Projecto:</label>
      <input
        type="text"
        name="project"
        value={formState.project}
        onChange={handleInputChange}
        required
        placeholder="----------------------Projecto"
      />
      <label className="label" htmlFor="name">Nome Completo:</label>
      <input
        type="text"
        name="name"
        value={formState.name}
        onChange={handleInputChange}
        required
        placeholder="--------------Nome Completo"
      />
      <label className="label" htmlFor="phone">Telefone / WhatsApp:</label>
      <input
        type="text"
        name="phone"
        value={formState.phone}
        onChange={handleInputChange}
        required
        placeholder="---------Telefone / WhatsApp"
      />
      <button className="buy-now-button_r" type="submit">RESERVA</button>
    </form>
  );
}

export default ReservacaoForm;
