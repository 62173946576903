import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './routes/About';
import Home from './routes/Home';
import Contacts from './routes/Contacts';
import Contents from './routes/Contents';
import Reservacao from './routes/Reservacao';
import Projects from './routes/Projects';
import ProjectMoreDetails from './routes/ProjectMoreDetails';
import MinutaDetails from './routes/MinutaDetails';
import DecretoDetails from './routes/DecretoDetails';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projectos" element={<Projects />} />
        <Route path="/sobrenos" element={<About />} />
        <Route path="/contactos" element={<Contacts />} />
        <Route path="/conteúdos" element={<Contents />} />
        <Route path="/reserva" element={<Reservacao />} />
        <Route path="/projectos/:id" element={<ProjectMoreDetails />} />
        <Route path="/conteúdos/minutas/:id" element={<MinutaDetails />} />
        <Route path="/conteúdos/decretos/:id" element={<DecretoDetails />} />
      </Routes>
    </Router>
  );
};

export default App;
