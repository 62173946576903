import React , { useEffect  , useState  } from 'react';
import Navbar_plataforma from '../components/Navbar_plataforma';

import Logo_Plataforma from '../components/Logo_Plataforma';

import './About.css';
import ImagePath1 from "../projectos/PLATAFORMA/OFERECEMOS/Icon_Conteudos2.webp";
import ImagePath2 from "../projectos/PLATAFORMA/OFERECEMOS/Icon_Projectos2.webp";
import ImagePath3 from "../projectos/PLATAFORMA/OFERECEMOS/Icon_Servicos2.webp";
import ImagePath4 from "../projectos/PLATAFORMA/logo_ARQUITECTOS.png";

import {FaLinkedin} from 'react-icons/fa'; // Import the icons

import datateam from "./datateam";

function Plataformacontent() {

  const currentYear = new Date().getFullYear(); // Get the current year
  const firstRow = datateam.slice(0, 6);
  const secondRow = datateam.slice(6, 12);

  const renderImages = (row) => {
    return row.map((data, index) => (
      <div key={index} className="team-member">
        
        <img src={data.imageUri} alt={`Image ${index + 1}`} />
        <p style={{ display: 'flex', alignItems: 'center' }}>
          {data.name}
                  
          {data.linkedin && (
            <a 
              href={data.linkedin} 
              target="_blank" 
              rel="noopener noreferrer" 
              style={{ marginLeft: '6%'}}
            >
              <FaLinkedin />
            </a>
          )}

        </p>
   
        <p1 style={{ display: 'flex', alignItems: 'center' }}>
            {data.title}
        </p1>


    </div>
    
    ));
  };

  const [showNavbar, setShowNavbar] = useState(false);

  const handleMouseEnter = () => {
    setShowNavbar(true);
  };

  const handleMouseLeave = () => {
    setShowNavbar(false);
  };
  

  return (
    <div className='container_aboutt'> {/*onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>*/}
      <div className="about_container">
        <div className="about_content">
          <div className="about_overlay">
              {/*showNavbar && <Navbar_plataforma />*/}
              <Navbar_plataforma />
               <Logo_Plataforma />

              <div className="centered-text">
              <h1>O TEU HUB</h1>
              <h2>DE HABITAÇÃO</h2>
              <p>Casa Própria é uma iniciativa moçambicana para massificação do acesso à Assistência Técnica à Habitação através da "auto-construção" emancipada. Para além de Conteúdos e Serviços, a plataforma oferece Projectos de construção modernos, evolutivos e concebidos à medida das capacidades e necessidades dos jovens, permitindo a estes construir bem ao seu próprio passo.</p>
          </div>


        </div>

      </div>
    </div>
    <div className = "oferecemos_text">
        <h2>OFERECEMOS</h2>
    </div> 
    <div className="oferecemos">
      <a href="/conteúdos">
        <img src={ImagePath1} alt="Plataforma 1" className="uniform-image" />
      </a>
      <a href="/projectos" >
        <img src={ImagePath2} alt="Plataforma 2" className="uniform-image" />
      </a>
      <a href="">
        <img src={ImagePath3} alt="Plataforma 3" className="uniform-image" />
      </a>
    </div>

    <div className="oferecemos_text_2">
           <p> Respeitando os preceitos urbanísticos, de harmonia e legalidade, esperamos
            contribuir também para o desenvolvimento harmonioso e humanizado das
            áreas peri-urbanas e de expansão - os bairros e cidades do futuro. </p>
      </div> 

    <div className = "nos_text">
        <h2>NÓS</h2>
    </div> 
{/*
      <div className="image-grid">
              <div className = "nos">
                {renderImages(firstRow)}
              </div>
              <div className = "nos">
                {renderImages(secondRow)}
              </div>
  </div>*/}
      <div className="image-grid">
        <div className="nos first-row">
          {renderImages(firstRow)}
        </div>
        <div className="nos second-row">
          {renderImages(secondRow)}
        </div>
      </div>


       
        <div className='iniciativa_text' >
          <h2>INICIATIVA</h2>
       {/* </div>
        <div className="iniciativa">*/}
           <img src={ImagePath4} alt="Plataforma 1" />
        </div>


        <div className="agradecimento">
           <h2>À todos, o nosso muito OBRIGADO!  </h2>
            <h3> De modo especial à Sua Excelência Carlos Alberto Fortes Mesquita (MOPRH), Dr. Leonardo Simão, Dr. Tomaz Salomão, Dr. Simão Muhai, Dr. Armindo Munguambe,
           Manuela J. Rebelo, Dr. Luís Lage, Dr. Eduardo Pejissanhe. Ao Abdul Afande, Abdul Tajú, Abneusa Manuel,  
          Anderson Chaúque, Catija Chipo, Danilo Tajú, Dário Afonso, Dionilte José, Emerson Comé, Éric Simbine, Gastão Cuembelo,
             José D. Seiuane, José Soares, Judite Jorge, Lasson Seiuane, Lumma Seiuane, Mariolizio Magalhães, Mauro Madara,
            Mendes Murucha, Miguel Junior, Nicole Henriques, Octávio Mutemba, Pedro Louro, Remi Estival, Ricardo P. Jorge, Sheila Danucha,
            Tavares Cebola, Yolanda José, Yonss José.</h3>



        </div>


        <div className="copyright_a">
          <footer className="copyright_a">
            <p>&copy; {currentYear}  Casa Própria. Todos Direitos Reservados.</p>
          </footer>
        </div>




</div>
  );
}

export default Plataformacontent;
