import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Navbar_plataforma.css";
import { SidebarData_plataforma } from "./SidebarData_plataforma";
import Footer_plataforma from './Footer_plataforma';

function Navbar_plataforma() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    const setWindowDimensions = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      document.documentElement.style.setProperty('--adjusted-width', `calc(${windowWidth}px - 10%)`);
      document.documentElement.style.setProperty('--adjusted-height', `calc(${windowHeight}px - 10%)`);
    };

    setWindowDimensions();

    window.addEventListener('resize', setWindowDimensions);

    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const handleClick = (item) => {
    if (item.protected) {
      return; // Skip handling click for protected items
    }

    // Perform action for non-protected items
    // ...
  };

  return (
    <>
      <div className={`navbar_plataforma ${sidebar ? "active" : ""}`}>
        <Link to="#" className="menu-bars_plataforma" onClick={showSidebar}>
        
            <FaIcons.FaBars onClick={showSidebar} />
          
        </Link>
      </div>
      <nav className={sidebar ? 'nav-menu_plataforma active' : 'nav-menu_plataforma'}>
        <ul className="nav-menu-items_plataforma" onClick={showSidebar}>
          {SidebarData_plataforma.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                {item.protected ? (
                  <span className="protected-item_plataforma">
                    {item.title}
                  </span>
                ) : (
                  <Link to={item.path} onClick={() => handleClick(item)}>
                    {item.title}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
      <Footer_plataforma />
    </>
  );
}

export default Navbar_plataforma;

