import React, { useState, useEffect } from 'react';
import Navbar_black from '../components/Navbar_black';
import Logo from '../components/Logo';
import "./Contacts.css";
import "./MinutaDetails.css";
import PublicityDisplay from './PublicityDisplay'; 
import data_minuta from "./data_minuta.js"


const MinutaDetails = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const [showNavbar, setShowNavbar] = useState(false);
  
    const handleMouseEnter = () => {
      setShowNavbar(true);
    };
  
    const handleMouseLeave = () => {
      setShowNavbar(false);
    };
  
    useEffect(() => {
      const setElementDimensions = () => {
        const rightCard = document.querySelector('.contact_right-container_ls');
        if (rightCard) {
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;
  
          const adjustedWidth = windowWidth - (windowWidth * 0.1); // Adjust width by 10%
          const adjustedHeight = windowHeight - (windowHeight * 0.1); // Adjust height by 10%
  
          rightCard.style.width = `${adjustedWidth}px`;
          rightCard.style.height = `${adjustedHeight}px`;
        }
      };
  
      // Initial call to set dimensions on component mount
      setElementDimensions();
  
      // Event listener for window resize to update dimensions
      window.addEventListener('resize', setElementDimensions);
  
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('resize', setElementDimensions);
      };
    }, []);

    // Function to extract a readable name from the URI
    const extractDocumentName = (uri) => {
        const namePart = uri.split('/').pop(); // Get the last part of the URI
        const withoutExtension = namePart.replace(/\.\w+$/, ''); // Remove the file extension
        return withoutExtension.replace(/_/g, ' '); // Replace underscores with spaces for readability
    };

    // Helper function to title-case a string except for certain words
    const toTitleCase = (str) => {
        const lowerCaseStr = str.toLowerCase();
        return lowerCaseStr.split(' ').map((word, index) => {
            // Always capitalize the first word and any word not in the exception list
            if (index === 0 || !['de', 'da', 'do', 'dos', 'e'].includes(word)) {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }
            return word;
        }).join(' ');
    };


    return (
      <div className='container_about'>
            <Navbar_black />
            <Logo />
            <div className="contactos_text">
                <h2>MINUTAS</h2>
            </div>
            
            <div className="minuta-info">
                <div className="documents-list">
                    {data_minuta.map((minuta, index) => (
                        <div key={index}>
                            {minuta.documents.map((uri, idx) => (
                                <div key={`${minuta.id}-${idx}`} className="document-link">
                                    <a href={uri} download>
                                        {uri.split('/').pop()} {/* Display only the file name */}
                                    </a>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div> 
            
            <div className="source_munita">
                <p>FONTE: Minutas cedidas pelo Conselho Municipal de Maputo - DM Kamavota</p>
            </div>

            <div className="copyright_munita">
                <footer className="copyright_munita">
                    <p>&copy; {currentYear} Casa Própria. Todos Direitos Reservados.</p>
                </footer>
            </div>
        </div>
    );
};
export default MinutaDetails;
