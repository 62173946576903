import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar_black from '../components/Navbar_black';
import Logo from '../components/Logo';
import data from './data';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Contacts.css';
import './ProjectMoreDetails.css';



function SampleNextArrow(props) {
  
  const { className, style, onClick } = props;
  return (
    <div
    className={`custom-arrow ${className}`}
      style={{ ...style}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`custom-arrow-left ${className}`}
      style={{ ...style}}
      onClick={onClick}
      
      />
  );
}


const ProjectMoreDetails = () => {



  const navigate = useNavigate();
  const { id } = useParams();
  const project = data.find((item) => item.id === id);
  const {
    description,
    price,
    equipatecnica,
    imagevideo,
    imagegif,
    iconsquantitatif,
    title,
    arquitetura_ilustracao,
    estrutura_hidraulica,
    eletricidade,
    administracao
  } = project;
  const carousel = useRef(); // Create a ref
  const [gifKey, setGifKey] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hasGif, setHasGif] = useState(!!imagegif); // Check if imagegif is available
  const [showNavbar, setShowNavbar] = useState(false);

  const handleMouseEnter = () => {
    setShowNavbar(true);
  };

  const handleMouseLeave = () => {
    setShowNavbar(false);
  };

  const handleBuyNowClick = () => {
    // Redirect to the contact page
    navigate('/reserva', { state: { title: project.title } });
  };

  const descriptionParts = description.split("||");
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    const setElementDimensions = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      const adjustedWidth = windowWidth - (windowWidth * 0.1); // Adjust width by 10%
      const adjustedHeight = windowHeight - (windowHeight * 0.1); // Adjust height by 10%

      // Apply adjustments to elements here
    };

    setElementDimensions();

    window.addEventListener('resize', setElementDimensions);

    return () => {
      window.removeEventListener('resize', setElementDimensions);
    };
  }, []);

  useEffect(() => {
    setHasGif(!!imagegif); // Update hasGif state based on imagegif changes
  }, [imagegif]);

  const currentYear = new Date().getFullYear(); // Get the current year
 
  const settings = {
    
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "23%",
    slidesToShow: 1,
    speed: 500,
    swipeToSlide:true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  

  return (
    <div className='container_about_proj_spe'>
      <Navbar_black />
      <Logo />
      <div>
        <h1 className="projects_title_h11">{project.title}</h1> 
        <h1 className="projects_price">{project.price}</h1>
      </div>

      <Slider {...settings}>

              
        {Object.keys(project).map((key, index) => {
          if (key.startsWith('imageUri')) {
            const image = project[key];
            if (image) {
              return (
                <div
                  className={`item ${
                    index === currentImageIndex ? 'active' : ''
                  }`}
                  key={key}
                >
                  <div className="image">
                    <img src={image} alt={`Image ${key}`} />
                  </div>
                </div>
              );
            }
          }
          return null;
        })}

      </Slider>

      <div className="right-containerp">
        <div className="image-and-buybutton-container">
          <div className="info_house">
            {/*<img src={iconsquantitatif} alt="info_house" />*/}
            {iconsquantitatif && <img key={gifKey} src={iconsquantitatif} alt="GIF" />}
          </div>
          <div>
            {/*<button className="buy-now-button" onClick={handleBuyNowClick}>
              RESERVE JÁ
            </button>*/}

            <button 
              className={iconsquantitatif ? "buy-now-button" : "buy-now-button_m"} 
              onClick={handleBuyNowClick}>
              RESERVE JÁ
            </button>
          </div>
        </div>
        <div className="description-container">
          <div className="equipa-tecnica">
            <div className="image-containerpp">
              {imagegif && <img key={gifKey} src={imagegif} alt="GIF" />}
            </div>
            <div className={hasGif ? 'text-below-images' : 'text-below-images-no-gif'}>
              <p className="team-title">Equipa técnica:</p>
              <p className="role">ARQUITECTURA E ILUSTRAÇÃO :</p>
              <p className="team-members">{arquitetura_ilustracao}</p>
              <p className="role">ESTRUTURA E HIDRÁULICA :</p>
              <p className="team-members">{estrutura_hidraulica}</p>
              <p className="role">ELECTRICIDADE :</p>
              <p className="team-members">{eletricidade}</p>
              <p className="role">ADMINISTRAÇÃO:</p>
              <p className="team-members">{administracao}</p>
            </div>
          </div>

          <div className="description_proj">
            {descriptionParts.map((part, index) => (
              <div key={index} style={{ marginLeft: "22px", marginBottom: '18px', fontSize: '18px' }}>
                {index < 2 || showFullDescription ? part.trim() : null}
                {index === 1 && !showFullDescription && (
                  <button onClick={toggleDescription} className="show-more-button">
                    Leia mais .....
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="copyright_pro_de">
        <footer className="copyright_pro_de">
          <p>&copy; {currentYear} Casa Própria. Todos Direitos Reservados.</p>
        </footer>
      </div>

    </div>



  );
};

export default ProjectMoreDetails;
