const faqItems = [
{id:"1",
    question: "Onde serão construídas as casas?",
    answer: "Em qualquer parte do país. CASA PRÓPRIA é uma plataforma que visa dar ferramentas ao dono de obra para que faça melhor a auto-construção, a medida do seu bolso e no local onde pretenda acomodar a sua família."
},
    
{id:"2",
    question: "Que tipo de alterações posso fazer ao projecto alterado?",
    answer: "Partindo do princípio que tenha encontrado o projecto tipo que é a sua cara poderá, por exemplo, pedir espaços maiores, pé-direito mais alto, aumentar compartimentos, eliminar ou aumentar paredes, desde que estas alterações não afectem demasiado a estrutura."
},
{id:"3",
    question: "Posso pagar o projecto em prestações?",
    answer: "Infelizmente por forma a aliviar a nossa contabilidade não aceitamos pagamentos em prestações."
},
{id:"4",
    question: "Em quanto tempo posso ter o projecto?",
    answer: "Em 5 dias úteis depois da confirmação do projecto tipo escolhido. Caso solicite alterações, este prazo poderá ser extendido em função da complexidade das alterações."
},
{id:"5",
    question: "Dão o projecto impresso ou em PDF? Tem termo de responsabilidade?",
    answer: "No final receberá 2 cópias impressas e uma em PDF com os respectivos termos de responsabilidade e requerimento para submissão do projecto para aprovação junto às autoridades competentes."
},
{id:"6",
    question: "Fazem entrega do projecto?",
    answer: "Os projectos são levantados nos nossos escritórios em Maputo. Caso prefira recebê-lo na sua casa, escritório, ou outro lugar, podemos arranjar a entrega desde que assuma as despesas associadas."
},
{id:"7",
    question: "Como adquiro um projecto?",
    answer: "Basta visitar os projectos disponíveis na plataforma através do website www.casa-propria.co.mz, escolher o que melhor responde às suas necessidades e capacidade. Poderá  consultar a nossa equipa para orientar-te na escolha. Segue-se o pagamento, preparação do pacote e o levantamento do projecto."
},
{id:"8",
    question: "O que é necessário para ter um projecto?",
    answer: "Deve ter um terreno onde iremos implantar o projecto, documentos do titular, documentos do terreno e o valor do projecto."
},
{id:"9",
    question: "Onde estão sedeados?",
    answer: "Os nossos escritórios estão localizados na Cidade de Maputo, na Av. Agostinho Neto nº 829."
},
{id:"10",
    question: "Comprando o projecto posso começar logo com a obra?",
    answer: "Depois de adquirido o projecto, este deve-se ser submetido às autoridades gestoras da terra onde pretende construir (Distrito ou Município). Contará com assistência técnica da nossa equipa até a aprovação do projecto e atribuíção da licença de construção. Só depois poderá começar com a obra."
},
{id:"11",
    question:"Posso mudar de projecto?",
    answer: "Depois da confirmação do projecto tipo escolhido, poderá mudar de projecto mediante pagamento de um novo projecto. Caso tenha começado a obra, será necessário avaliar o estágio da obra para melhor orientarmos na escolha do novo projecto."
},
{id:"12",
    question: "Qual é o custo do projecto?",
    answer: "O custo de projecto varia de 20mil à 50mil Meticais. Os projectos tipo com área total de construção inferior a 100m2 custam 20mil e caso peça alterações o mesmo custará 30mil Meticais. Os projectos tipo com área total de construção superior a 100m2 custam 30mil e caso peça alterações o mesmo custará 50mil Meticais."
},
{id:"13",
    question: "É possível saber o custo de construção de cada projecto?",
    answer: "É possível, cada projecto tem um preço de referência e será ainda facultada uma lista de materiais global e por cada fase assim o dono de obra poderá actualizar esta estimativa com base na disponibilidade de materiais, mão de obra e equipamentos na sua região."
},
{id:"14",
question: "Estou em Nampula, posso construir o projecto aqui?",
answer: "Pode. Sempre depois de adquirir a licença de construção junto ao Município ou Distrito onde pretende construir."
},
{id:"15",
question: "A compra do projecto dá direito à fiscalização ou acompanhamento da obra?",
answer: "Não. A fiscalização poderá ser solicitada a parte e nesta fase piloto ela apenas estará disponível na Grande Maputo (Cidade de Maputo, Matola, Marracuene, Boane e Matola Rio). O acompanhamento gratuíto será feito através dos demais Serviços disponíveis na plataforma onde poderemos aconselhá-lo sobre as melhores soluções para os seus problema ou fase da obra."
},
{id:"16",
question: "Vocês fazem consultoria?",
answer: "Sim. A consultoria para escolha do projecto está incluída no custo do projecto. Outras consultorias poderão ser discutidas entre a nossa equipa e o dono de obra."
},
{id:"17",
question: "Esse preço é de construção?",
answer: "Não. O preço é do projecto. O dono de obra pode optar pela auto-construção ou contratação de empreiteiro, em ambos casos a plataforma pode dar assistência através da secção de Serviços."
}


]   

export default faqItems;
    