

export const SidebarData_plataforma = [
  {
    title: "Home",
    path: "/",
    cName: "nav-text_plataforma",
    protected: false, // Set to true for protected routes
  },
  {
    title: "A Plataforma",
    path: "/sobrenos",
    cName: "nav-text_plataforma",
    protected: false, // Set to true for protected routes
  },
  {
    title: "Conteúdos",
    path: "/conteúdos",
    cName: "nav-text_plataforma",
    protected: false, // Set to true for protected routes
  },
  {
    title: "Projectos",
    path: "/projectos",
    cName: "nav-text_plataforma",
    protected: false, // Set to true for protected routes
  },
  {
    title: "Serviços",
    path: "/Servicos",
    cName: "nav-text_plataforma",
    protected: true, // Set to true for protected routes
  },
  {
    title: "Contactos",
    path: "/contactos",
    cName: "nav-text_plataforma",
    protected: false, // Set to true for protected routes
  },
  {
    title: "Reserva",
    path: "/reserva",
    cName: "nav-text_plataforma",
    protected: false, // Set to true for protected routes
  },
];