const data_minuta = [
  {
      id: "01",
      name: "Municipio de Maputo",
      documents: [
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MINUTAS/MUNICIPIO_DE_MAPUTO/NR+01+MINUTA+REQUERIMENTO+PEDIDO+DE+REGULARIZACÃO+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MINUTAS/MUNICIPIO_DE_MAPUTO/NR+02+MINUTA+REQUERIMENTO+PEDIDO+DE+PLANTA+TOPOGRÁFICA+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MINUTAS/MUNICIPIO_DE_MAPUTO/NR+04+MINUTA+REQUERIMENTO+PEDIDO+DE+APROVAÇÃO+DO+PROJECTO+DE+ARQUITECTURA+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MINUTAS/MUNICIPIO_DE_MAPUTO/NR+05+MINUTA+REQUERIMENTO+PEDIDO+DE+APROVAÇÃO+DE+PROJECTOS+COMPLEMENTARES+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MINUTAS/MUNICIPIO_DE_MAPUTO/NR+06+MINUTA+REQUERIMENTO+PEDIDO+DE+LICENÇA+DE+CONSTRUÇÃO+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MUNICIPIO_DE_MAPUTO/NR+07+MINUTA+REQUERIMENTO+PEDIDO+DE+PRORROGAÇÃO+DA+LICENÇA+DE+CONSTRUÇÃO+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MINUTAS/MUNICIPIO_DE_MAPUTO/NR+08+MINUTA+REQUERIMENTO+PEDIDO+DE+LICENÇA+DE+UTILIZAÇÃO+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MINUTAS/MUNICIPIO_DE_MAPUTO/NR+09+MINUTA+REQUERIMENTO+PEDIDO+DE+AUTORIZAÇÃO+PARA+OBRAS+DE+ALTERAÇÕES+INTERNAS+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MINUTAS/MUNICIPIO_DE_MAPUTO/NR+10+MINUTA+REQUERIMENTO+CERTIDÃO+DE+BENFEITORIAS+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MINUTAS/MUNICIPIO_DE_MAPUTO/NR+12+MINUTA+REQUERIMENTO+PEDIDO+DE+FORNECIMENTO+DE+CÓPIAS+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/INUTAS/MUNICIPIO_DE_MAPUTO/NR+15+MINUTA+REQUERIMENTO+PEDIDO+DE+CERTIDÃO+DE+PROPRIEDADE+HORINZOTAL+MUNICÍPIO.doc",
        "https://minutascasapropria.s3.eu-west-3.amazonaws.com/CONTEUDO/MINUTAS/MUNICIPIO_DE_MAPUTO/NR+16+MINUTA+REQUERIMENTO+LICENÇA+DE+6ª+CATEGORIA+MUNICÍPIO.doc",

      ]
    }
  ]
    export default data_minuta;
    