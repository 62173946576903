import React, { useEffect, useState } from 'react';
import Plataformacontent from './Plataformacontent';

const Sobrenos = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Plataformacontent windowDimensions={windowDimensions} />
    </>
  );
};

export default Sobrenos;
