import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { SidebarData } from "./SidebarData";
import Footer from './Footer';

function Navbar() {
  const [sidebar, setSidebar] = useState(true); 
  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    const setWindowDimensions = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      document.documentElement.style.setProperty('--adjusted-width', `calc(${windowWidth}px - 10%)`);
      document.documentElement.style.setProperty('--adjusted-height', `calc(${windowHeight}px - 10%)`);
    };

    setWindowDimensions();

    window.addEventListener('resize', setWindowDimensions);

    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const handleClick = (item) => {
    if (item.protected) {
      return; 
    }
  };

  return (
    <>
      <div className={`navbar`}>
        <Link to="#" className="menu-bars" onClick={showSidebar}>
          <FaIcons.FaBars />
        </Link>
      </div>
      <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className="nav-menu-items">
          {SidebarData.map((item, index) => {
            return (
              <li key={index} className={item.cName}>
                {item.protected ? (
                  <span className="protected-item">
                    {item.title}
                  </span>
                ) : (
                  <Link to={item.path} onClick={() => handleClick(item)}>
                    {item.title}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
      <Footer />
    </>
  );
}

export default Navbar;
