const data_anexos = [
  {
      id: "02AC",
      name: "Projecto 02AC",
      imageUri:  require("../projectos/02AC/Habitacao-casa-propria-moderna-anexo-chapa-01.webp"),
       title: "Anexo",
       title1:"CHAPA",
       title2:"S02",
       title3:'T0 ou T1',
      buttonText: "Veja mais",
      price: "20,000 Mt",
      link: "/projectos/02AC",
      imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
      imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
      hoverStyles: {
         opacity: 1,
         background: 'rgba(0, 0, 0, 0.6)',
         marginTop: '0%',
         marginLeft: '0%',
         height: '${(52 / 9) * 16}vw',
         // Add other unique hover styles for this image
       }
    },
   {
        id: "02AL",
        name: "Projecto 02AL",
        imageUri:  require("../projectos/02AL/Habitacao-casa-propria-moderna-anexo-laje-betao-01.webp"),
        title: "Anexo",
        title1:"LAJE",
        title2:"S02",
        title3:'T0 ou T1',
        buttonText: "Veja mais",
        price: "20,000 Mt",
        link: "/projectos/02AL",
        imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
      imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
      hoverStyles: {
         opacity: 1,
         background: 'rgba(0, 0, 0, 0.6)',
         marginTop: '0%',
         marginLeft: '0%',
         height: '${(52 / 9) * 16}vw',
         // Add other unique hover styles for this image
       }
      },
  {
      id: "MV01",
      name: "Projecto MV01",
      imageUri:  require("../projectos/MV01/Casa-propria-muro-vedacao-opaco-01.webp"),
      title: "Muro de vedação",
      title1:"",
      title2:"01",
      title3:'',
      buttonText: "Veja mais",
  
      price: "10,000 Mt",
      link: "/projectos/MV01",
      imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
      imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
      hoverStyles: {
         opacity: 1,
         background: 'rgba(0, 0, 0, 0.6)',
         marginTop: '0%',
         marginLeft: '0%',
         height: '${(52 / 9) * 16}vw',
         // Add other unique hover styles for this image
       }
    },
  {
      id: "MV02A",
      name: "Projecto MV01",
      imageUri:  require("../projectos/MV02A/Casa-propria-muro-vedacao-grade-01.webp"),
      title: "Muro de vedação",
      title1:"",
      title2:"02A",
      title3:'',
      buttonText: "Veja mais",
      price: "10,000 Mt",
      link: "/projectos/MV02A",
      imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
      imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
      hoverStyles: {
         opacity: 1,
         background: 'rgba(0, 0, 0, 0.6)',
         marginTop: '0%',
         marginLeft: '0%',
         height: '${(52 / 9) * 16}vw',
         // Add other unique hover styles for this image
       }
        },
  {
      id: "MV02B",
      name: "Projecto MV02B",
      imageUri:  require("../projectos/MV02B/Casa-propria-muro-vedacao-grade-chapa-01.webp"),
      title: "Muro de vedação",
      title1:"",
      title2:"02B",
      title3:'',
      buttonText: "Veja mais",
      price: "10,000 Mt",
      link: "/projectos/MV02B",
      imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
      imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
      hoverStyles: {
         opacity: 1,
         background: 'rgba(0, 0, 0, 0.6)',
         marginTop: '0%',
         marginLeft: '0%',
         height: '${(52 / 9) * 16}vw',
         // Add other unique hover styles for this image
       }
    },
  {
      id: "MV02C",
      name: "Projecto MV02C",
      imageUri:  require("../projectos/MV02C/Casa-propria-muro-vedacao-grade-portico-entrada-01.webp"),
      title: "Muro de vedação",
      title1:"",
      title2:"02C",
      title3:'',
      buttonText: "Veja mais",
      price: "10,000 Mt",
      link: "/projectos/MV02C",
      imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
      imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
      hoverStyles: {
         opacity: 1,
         background: 'rgba(0, 0, 0, 0.6)',
         marginTop: '0%',
         marginLeft: '0%',
         height: '${(52 / 9) * 16}vw',
         // Add other unique hover styles for this image
       }
    },
  {
      id: "MV02D",
      name: "Projecto MV02D",
      imageUri:  require("../projectos/MV02D/Casa-propria-muro-vedacao-grade-portico-entrada-01.webp"),
      title: "Muro de vedação",
      title1:"",
      title2:"02D",
      title3:'',
      buttonText: "Veja mais",
      price: "10,000 Mt",
      link: "/projectos/MV02D",
      imageSize:{ width: '52vw' , height:'${(52 / 9) * 16}vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',fontFamily: 'Futura' },
      imageSizeTitle1:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'54vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {fontSize: '1vw',color:'white',marginBottom:'-10vw',marginLeft:'46vw',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'54vw',alignItems: 'right',fontFamily: 'Futura'},
      hoverStyles: {
         opacity: 1,
         background: 'rgba(0, 0, 0, 0.6)',
         marginTop: '0%',
         marginLeft: '0%',
         height: '${(52 / 9) * 16}vw',
         // Add other unique hover styles for this image
       }
    },
  
  
  
  
  
  
  
    ]
  export default data_anexos;
  