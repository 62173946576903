import React, { useState, useEffect } from 'react';
import Navbar_black from '../components/Navbar_black';
import Logo from '../components/Logo';
import "./Contacts.css";
import PublicityDisplay from './PublicityDisplay';
import LeftCard from './LeftCard';
import RightCard from './RightCard';

const Contactos = () => {
  const currentYear = new Date().getFullYear(); // Get the current year
  const [showNavbar, setShowNavbar] = useState(false);

  const handleMouseEnter = () => {
    setShowNavbar(true);
  };

  const handleMouseLeave = () => {
    setShowNavbar(false);
  };

  useEffect(() => {
    const setElementDimensions = () => {
      const rightCard = document.querySelector('.contact_right-container_ls');
      if (rightCard) {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        const adjustedWidth = windowWidth - (windowWidth * 0.1); // Adjust width by 10%
        const adjustedHeight = windowHeight - (windowHeight * 0.1); // Adjust height by 10%

        rightCard.style.width = `${adjustedWidth}px`;
        rightCard.style.height = `${adjustedHeight}px`;
      }
    };

    // Initial call to set dimensions on component mount
    setElementDimensions();

    // Event listener for window resize to update dimensions
    window.addEventListener('resize', setElementDimensions);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', setElementDimensions);
    };
  }, []);

  return (
    <div className='container_about' > {/* onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>>*/}
      {/*showNavbar && <Navbar_black />*/}
      <Navbar_black />
      <Logo />
      <div className="contactos_text">
        <h2>CONTACTOS</h2>
      </div>
      <div className="contact_container_pub">
        {/*  <PublicityDisplay />*/}
      </div>
      <div className="contact_right-container">
        <LeftCard />
        <RightCard />
      </div>
      <div className="copyright_c">
        <footer className="copyright_c">
          <p>&copy; {currentYear} Casa Própria. Todos Direitos Reservados.</p>
        </footer>
      </div>
    </div>
  );
};

export default Contactos;
