import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar_black from '../components/Navbar_black';
import Logo from '../components/Logo';
import datacontent from './datacontent';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Contacts.css';
import './ProjectMoreDetails.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`custom-arrow ${className}`}
      style={{ ...style, display: "block" }} // Ensure arrows are always displayed
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`custom-arrow-left ${className}`}
      style={{ ...style, display: "block" }} // Ensure arrows are always displayed
      onClick={onClick}
    />
  );
}

const Contents = () => {
  const { id } = useParams();
  const [currentVideoIndex, setCurrentVideoIndex] = useState(-1); // Initialize as -1 to indicate no video is playing initially

  const handleVideoClick = (index) => {
    if (currentVideoIndex !== index) {
      setCurrentVideoIndex(index);
    } else {
      setCurrentVideoIndex(-1); // Clicking the same video again should stop playback
    }
  };

 {/*} const handleBoxClick = (boxNumber) => {
    console.log(`Box ${boxNumber} clicked!`);
    // You can perform any actions here when a box is clicked
  };*/}
  const handleBoxClick = (boxNumber) => {
    if (boxNumber === 1) {
      navigate('/conteúdos/minutas/1'); // Updated path for Minutas
    } else if (boxNumber === 2) {
      navigate('/conteúdos/decretos/1'); // Updated path for Decretos
    }
  };
  

  const navigate = useNavigate(); // Initialize the navigate function
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "23%",
    slidesToShow: 1,
    speed: 500,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <div className='container_about_proj_spe'>
      <Navbar_black />
      <Logo />
      <div className="contactos_text">
        <h2>CONTEÚDOS</h2>
      </div>

      <Slider {...settings}>
        {datacontent.map((item, index) => (
          <div key={index} className="video-container">
              <iframe
                src={item.imagevideo}
                allowFullScreen
                onClick={() => handleVideoClick(index)}
              ></iframe>
          </div>
        ))}
      </Slider>

      <style>{`
        .video-container {
          position: relative;
          margin-top: 4%;
          padding-bottom: 56.25%; /* 16:9 aspect ratio */
        }
        .video-container iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 98%;
          height: 100%;
        }
        .box {
          width: 100px; /* Adjust the width as needed */
          height: 100px; /* Adjust the height as needed */
          background-color: #ffdd99;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px;
          cursor: pointer;
          user-select: none; /* Prevent text selection */
        }

        .box1 {
          width: 200px; /* Updated width */
          height: 50px; /* Updated height */
          background-color: #ffdd99;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px;
          cursor: pointer;
          user-select: none; /* Prevent text selection */
          font-weight: bold; /* Make text bold */
          border-radius: 5px; /* Rounded corners */
          box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Add shadow for depth */
          transition: transform 0.2s; /* Smooth transform on click */
        }
        .box1:hover {
          transform: scale(1.05); /* Slightly enlarge boxes on hover */
          box-shadow: 0 4px 8px rgba(0,0,0,0.3); /* Enhance shadow on hover */
        }
      `}</style>


<div className="minutas" style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
  <div className="box1" onClick={() => handleBoxClick(1)}>MINUTAS</div>
  <div className="box1" onClick={() => handleBoxClick(2)}>DECRETOS</div>
</div>

      <div className="copyright_pro_de">
        <footer className="copyright_pro_de">
          <p>&copy; {new Date().getFullYear()} Casa Própria. Todos Direitos Reservados.</p>
        </footer>
      </div>
    </div>
  );
};

export default Contents;
