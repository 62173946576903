// Logo.js
import React from 'react';
import './Logo_Plataforma.css';
import logoImage from '../projectos/logo/logo_cp_rect_fundo_01.webp';/*slogo_cp_sqr.png'; */

function Logo() {
  return (
    <div className="logop">
      <a href="/" target="_blank" rel="noopener noreferrer">
         <img src={logoImage} alt="Logo" />
      </a>
    </div>
  );
}

export default Logo;


