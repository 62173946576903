import React, { useEffect, useState, useCallback } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 

import './Home.css';
import data from './datahome';
import Logo from '../components/Logo';
import Navbar from '../components/Navbar';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showNavbar, setShowNavbar] = useState(false);

  const handleAutoTransition = useCallback(() => {
    const nextIndex = (currentIndex + 1) % data.length;
    setCurrentIndex(nextIndex);
  }, [currentIndex]);

  useEffect(() => {
    const intervalId = setInterval(handleAutoTransition, 5000);
    return () => clearInterval(intervalId);
  }, [handleAutoTransition]);

  const handleMouseEnter = () => {
    setShowNavbar(true);
  };

  const handleMouseLeave = () => {
    setShowNavbar(false);
  };
  
  return (

    <a href="/projectos" style={{ display: 'block' }}>
    <div className="home" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
    
    {showNavbar && (
  <>
    <div className="overlay-background"></div>
    <Navbar />
  </>
)}
      <Logo />

      <Carousel className="carousel" interval={5000} activeIndex={currentIndex} controls={false}>
        {data.map((item, index) => (
          <Carousel.Item key={index}>
              <img
                style={{ width: '100%', display: 'block' }}
                src={item.imageUri}
                alt={item.heading}
              />
          </Carousel.Item>
        ))}
      </Carousel>

    </div>

    </a>
  );
}

export default Home;
