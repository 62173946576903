// LeftCard.js
import React from 'react';
import './LeftCard.css';

const LeftCard = () => {
  return (
       <div className="contact-info">
            <h1>Contacte-nos</h1>
            <h2>Av. Agostinho Neto 829, Maputo</h2>
            <h3>ola@casa-propria.co.mz</h3>
            <h4>+258 87 022 0044</h4>
            {/* ........*/}
            {/*<h1>Siga-nos</h1>*/}
            <h1 className="follow-us">Siga-nos</h1>
             <a href="https://www.facebook.com/casapropriahub/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
               <h5>Facebook</h5>
             </a>
             <a href="https://instagram.com/casapropriahub?igshid=OGQ5ZDc2ODk2ZA%3D%3D$&utm_source=qr" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
               <h5>Instagram</h5>
             </a>
             <a href="https://www.youtube.com/@casapropriahub" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                <h6>Youtube</h6>
             </a>
             <a href="https://www.linkedin.com/company/casa-pr%C3%B3pria/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                <h6>Linkedin </h6>
             </a>

             <div  className="fale2" >  
              <h61> FALE </h61>
             </div>

             <div  className="fale" >  
              <h61> CONNOSCO! </h61>
             </div>

        </div>
  );
};

export default LeftCard;

