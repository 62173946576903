const data_casas = [
  {
      id: "01N",
      name: "Projecto 01N",
      imageUri:  require("../projectos/01N/Habitacao-casa-propria-moderna-faseada-01.webp"),
      title: "Núcleo",
      title1:"LAJE ou CHAPA",
      title2:"S01",
      title3:'T0 ou T1',
      price: "30,000 Mt",
      buttonText: "Veja mais",
      link: "/projectos/01N",
      imageSize:{ width: '58vw' , height: '32.625vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',alignItems: 'right' },
      imageSizeTitle1:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right'},
      imageSizeTitle:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'61vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {width: '15vw',fontSize: '1vw',color:'white',marginTop:'-40vw' ,marginLeft:'53vw',fontFamily: 'Futura'} ,
      hoverStyles: {
        opacity: 1,
        background: 'rgba(0, 0, 0, 0.6)',
        marginTop: '0vw',
        marginLeft: '0vw',
        height: '32.625vw',
        // Add other unique hover styles for this image
      }
    },
   {
      id: "01GTC",
      name: "Projecto 01GTC ",
      imageUri: require("../projectos/01GTC/Habitacao-casa-propria-moderna-geminada-chapa-01.webp"),
      title: "Geminada de Trás",
      title1: "CHAPA",
      title2: "S01",
      title3:'T0 à T3',
      price: "30,000 Mt",
      buttonText: "Veja mais",
      link: "/projectos/01GTC",
  
      imageSize:{ width: '58vw' , height: '32.625vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',alignItems: 'right' },
      imageSizeTitle1:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right'},
      imageSizeTitle:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'61vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {width: '15vw',fontSize: '1vw',color:'white',marginTop:'-40vw' ,marginLeft:'53vw',fontFamily: 'Futura'} ,
      hoverStyles: {
            opacity: 1,
            background: 'rgba(0, 0, 0, 0.6)',
  
        marginTop: '0%',
        marginLeft: '0%',
            height: '32.625vw',
            // Add other unique hover styles for this image
          }
  
      },
  
    {
      id: "01GTL",
      name: "Projecto 01GTL",
      imageUri: require("../projectos/01GTL/Habitacao-casa-propria-moderna-geminada-laje-betao-01.webp"),
      title: "Geminada de Trás",
      title1: "LAJE",
      title2: "S01",
      title3:"T0 à T3",
      price: "30,000 Mt",
      buttonText: "Veja mais",
      link: "/projectos/01GTL",
      imageSize:{ width: '58vw' , height: '32.625vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',alignItems: 'right' },
      imageSizeTitle1:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right'},
      imageSizeTitle:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'61vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {width: '15vw',fontSize: '1vw',color:'white',marginTop:'-40vw' ,marginLeft:'53vw',fontFamily: 'Futura'} ,
  
      hoverStyles: {
        opacity: 1,
        background: 'rgba(0, 0, 0, 0.6)',
  
        marginTop: '0vw',
        marginLeft: '0vw',
        height: '32.625vw',
        // Add other unique hover styles for this image
      }
    },
  
    {
      id: "01IPL",
      name: "Projecto 01IPL ",
      imageUri: require("../projectos/01IPL/Habitacao-casa-propria-moderna-2pisos-laje-betao-01.webp"),
      title: "Isolada 2 Pisos",
      title1: "LAJE",
      title2: "S01",
      title3:"T0 à T3",
      price: "30,000 Mt",
      buttonText: "Veja mais",
      link: "/projectos/01IPL",
      imageSize:{ width: '58vw' , height: '32.625vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',alignItems: 'right' },
      imageSizeTitle1:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right'},
      imageSizeTitle:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'61vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {width: '15vw',fontSize: '1vw',color:'white',marginTop:'-40vw' ,marginLeft:'53vw',fontFamily: 'Futura'} ,
  
      hoverStyles: {
        opacity: 1,
        background: 'rgba(0, 0, 0, 0.6)',
  
        marginTop: '0vw',
        marginLeft: '0vw',
        height: '32.625vw',
        // Add other unique hover styles for this image
      }
  
    },
  
    {
          id: "02GL",
          name: "Projecto 02GL",
          imageUri:  require("../projectos/02GL/Habitacao-casa-propria-moderna-geminada-evolutiva-laje-01.webp"),
          title: "Geminada",
          title1:'LAJE',
          title2:"S02",
          title3:"T0 à T3",
          price: "20,000 Mt",
           buttonText: "Veja mais",
          link: "/projectos/02GL",
          imageSize:{ width: '58vw' , height: '32.625vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',alignItems: 'right' },
          imageSizeTitle1:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right'},
          imageSizeTitle:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
          imageSizeTitle3:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
          imageSizeTitle2:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
          buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'61vw',alignItems: 'right',fontFamily: 'Futura'},
          buttonlink:  {width: '15vw',fontSize: '1vw',color:'white',marginTop:'-40vw' ,marginLeft:'53vw',fontFamily: 'Futura'} ,
                  hoverStyles: {
                    opacity: 1,
                    background: 'rgba(0, 0, 0, 0.6)',
  
                marginTop: '0vw',
                marginLeft: '0vw',
                    height: '32.625vw',
                    // Add other unique hover styles for this image
                  }
  
        },
    {
      id: "02GC",
      name: "Projecto 02GC",
      imageUri:  require("../projectos/02GC/Habitacao-casa-propria-moderna-geminada-evolutiva-chapa-01.webp"),
      title: "Geminada",
      title1: "CHAPA",
      title2: "S02",
      title3: "T0 à T3",
      price: "20,000 Mt",
      link: "/projectos/02GC",
      buttonText: "Veja mais",
      imageSize:{ width: '58vw' , height: '32.625vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',alignItems: 'right' },
      imageSizeTitle1:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right'},
      imageSizeTitle:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'61vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {width: '15vw',fontSize: '1vw',color:'white',marginTop:'-40vw' ,marginLeft:'53vw',fontFamily: 'Futura'} ,
      hoverStyles: {
        opacity: 1,
        background: 'rgba(0, 0, 0, 0.6)',
        marginTop: '0%',
        marginLeft: '0%',
        height: '32.625vw',
            // Add other unique hover styles for this image
          }
      },
  
  {
      id: "01GFC",
      name: "Projecto 01GFC",
      imageUri:  require("../projectos/01GFC/Habitacao-casa-propria-moderna-geminada-chapa-01.webp"),
      title: "Geminada de Frente",
      title1: "CHAPA",
      title2: "S01",
      title3:"T0 à T3",
      price: "30,000 Mt",
      buttonText: "Veja mais",
      link: "/projectos/01GFC",
      imageSize:{ width: '58vw' , height: '32.625vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',alignItems: 'right' },
      imageSizeTitle1:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right'},
      imageSizeTitle:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'61vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {width: '15vw',fontSize: '1vw',color:'white',marginTop:'-40vw' ,marginLeft:'53vw',fontFamily: 'Futura'} ,
      hoverStyles: {
            opacity: 1,
            background: 'rgba(0, 0, 0, 0.6)',
  
        marginTop: '0vw',
        marginLeft: '0vw',
            height: '32.625vw',
            // Add other unique hover styles for this image
          }
    },
  
  
    {
        id: "01GFL",
        name: "Projecto 01GFL",
        imageUri:  require("../projectos/01GFL/Habitacao-casa-propria-moderna-geminada-laje-betao-01.webp"),
        title: "Geminada de Frente",
        title1: "LAJE",
        title2: "S01",
        title3:"T0 à T3",
        price: "30,000 Mt",
        buttonText: "Veja mais",
        link: "/projectos/01GFL",
        imageSize:{ width: '58vw' , height: '32.625vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',alignItems: 'right' },
        imageSizeTitle1:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right'},
        imageSizeTitle:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
        imageSizeTitle3:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
        imageSizeTitle2:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
        buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'61vw',alignItems: 'right',fontFamily: 'Futura'},
        buttonlink:  {width: '15vw',fontSize: '1vw',color:'white',marginTop:'-40vw' ,marginLeft:'53vw',fontFamily: 'Futura'} ,
        hoverStyles: {
                  opacity: 1,
                  background: 'rgba(0, 0, 0, 0.6)',
  
        marginTop: '0vw',
        marginLeft: '0vw',
                  height: '32.625vw',
                  // Add other unique hover styles for this image
                }
  
      },
  
    {
      id: "01IRC",
      name: "Projecto 01IRC ",
      imageUri: require("../projectos/01IRC/Habitacao-casa-propria-moderna-isolada-chapa-01.webp"),
       title: "Isolada Rés do Chão",
      title1:"CHAPA",
      title2:"S01",
      title3:"T0 à T3",
       price: "30,000 Mt",
      buttonText: "Veja mais",
      link: "/projectos/01IRC",
      imageSize:{ width: '58vw' , height: '32.625vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',alignItems: 'right' },
      imageSizeTitle1:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right'},
      imageSizeTitle:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'61vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {width: '15vw',fontSize: '1vw',color:'white',marginTop:'-40vw' ,marginLeft:'53vw',fontFamily: 'Futura'} ,
    hoverStyles: {
            opacity: 1,
            background: 'rgba(0, 0, 0, 0.6)',
  
        marginTop: '0vw',
        marginLeft: '0vw',
            height: '22.95vw',
            // Add other unique hover styles for this image
          }
  
    },
  
  {
      id: "01IRL",
      name: "Projecto 01IRL",
      imageUri:  require("../projectos/01IRL/Habitacao-casa-propria-moderna-isolada-laje-betao-01.webp"),
      title: "Isolada Rés do Chão",
      title1: "LAJE",
      title2: "S01",
      title3:"T0 à T3",
      price: "30,000 Mt",
      buttonText: "Veja mais",
      link: "/projectos/01IRL",
      imageSize:{ width: '58vw' , height: '32.625vw' ,marginBottom:'0vw',marginTop:'3vw' ,marginLeft :'.5vw',alignItems: 'right' },
      imageSizeTitle1:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right'},
      imageSizeTitle:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontWeight: 'bold',fontFamily: 'Futura'},
      imageSizeTitle3:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      imageSizeTitle2:{  marginLeft:'61vw' ,marginBottom:'0vw',marginTop:'-2.5vw', fontSize: '1vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonprice: {width: '15vw',fontSize: '1vw',color:'red',marginTop:'-3vw',marginLeft:'61vw',alignItems: 'right',fontFamily: 'Futura'},
      buttonlink:  {width: '15vw',fontSize: '1vw',color:'white',marginTop:'-40vw' ,marginLeft:'53vw',fontFamily: 'Futura'} ,
        hoverStyles: {
            opacity: 1,
            background: 'rgba(0, 0, 0, 0.6)',
  
        marginTop: '0vw',
        marginLeft: '0vw',
            height: '32.625vw',
            // Add other unique hover styles for this image
          }
    },
  
  
  
  
    ]
  
  
  export default data_casas;
  