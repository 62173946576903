/*import React from "react";
 import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";*/

export const SidebarData_black = [
  {
    title: "Home",
    path: "/",
    cName: "nav-text_black",
    protected: false, // Set to true for protected routes
  },
  {
    title: "A Plataforma",
    path: "/sobrenos",
    cName: "nav-text_black",
    protected: false, // Set to true for protected routes
  },
  {
    title: "Conteúdos",
    path: "/conteúdos",
    cName: "nav-text_black",
    protected: false, // Set to true for protected routes
  },
  {
    title: "Projectos",
    path: "/projectos",
    cName: "nav-text_black",
    protected: false, // Set to true for protected routes
  },
  {
    title: "Serviços",
    path: "/Servicos",
    cName: "nav-text_black",
    protected: true, // Set to true for protected routes
  },
  {
    title: "Contactos",
    path: "/contactos",
    cName: "nav-text_black",
    protected: false, // Set to true for protected routes
  },
  {
    title: "Reserva",
    path: "/reserva",
    cName: "nav-text_black",
    protected: false, // Set to true for protected routes
  },
];