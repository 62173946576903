import React from 'react';
import "./Footer.css";
import { FaFacebook, FaInstagram, FaEnvelope ,FaYoutube,FaLinkedin} from 'react-icons/fa'; // Import the icons

function Footer() {
  return (
    <div className="footer">
      <div className="social-icons0">
        <a href="https://www.facebook.com/casapropriahub/" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
      {/*</div>
      <div className="social-icons1">*/}
        <a href="https://instagram.com/casapropriahub?igshid=OGQ5ZDc2ODk2ZA%3D%3D$&utm_source=qr" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
        <a href="https://www.linkedin.com/company/casa-pr%C3%B3pria/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
     {/* </div>
      <div className="contact-icons0">*/}
        <a href="https://www.youtube.com/@casapropriahub"  target="_blank" rel="noopener noreferrer">
          <FaYoutube />
        </a>
      {/*</div>
      <div className="contact-icons1">*/}
        <a href="mailto:ola@casa-propria.co.mz">
          <FaEnvelope />
        </a>
      </div>
    </div>
  );
}

export default Footer;
