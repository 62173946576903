import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure you import Bootstrap CSS
import "./RightCard.css"
import faqItems from "./faq"

const RightCard = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const handleSelectChange = (eventKey) => {
    setSelectedQuestion(eventKey);
  };

  return (
    <div className="right-cardss">
      <h1>Questões mais frequentes</h1>
      <Dropdown onSelect={handleSelectChange}>
        <Dropdown.Toggle variant="info" id="dropdown-basic" className="custom-dropdown-toggle" > {/*style={{ fontSize: '18px', padding: '5px 10px' }}>*/}
          Selecione uma pergunta 
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {faqItems.map((item) => (
            <Dropdown.Item key={item.id} eventKey={item.id}>
              {item.question}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {/* Display selected question */}
      {selectedQuestion && (
        <div className="faq-answer">
          <h3>{faqItems.find((item) => item.id === selectedQuestion)?.question}</h3>
          <p>{faqItems.find((item) => item.id === selectedQuestion)?.answer}</p>
        </div>
      )}

      {/* Display default question initially */}
      {!selectedQuestion && (
        <div className="faq-answer">
          <h3>{faqItems[0]?.question}</h3>
          <p>{faqItems[0]?.answer}</p>
        </div>
      )}
    </div>
  );
};

export default RightCard;
