const  datateam = [

  {
    id: "1",
    name: "Lasson" ,
    title: "Sócio fundador - Director",// Seiuane",
    imageUri: require("../projectos/PLATAFORMA/NOS/Lasson_Seiuane.webp"),
    linkedin:"https://www.linkedin.com/in/lasson-seiuane-7896a2118/"
  },
  {
    id: "2",
    name: "Catija",//  Chipo",""
    title:"Sócia fundadora                       ",
    imageUri: require("../projectos/PLATAFORMA/NOS/Catija_Chipo.webp"),
    linkedin:"https://www.linkedin.com/in/catijachipo/"
  },
  {
    id: "3",
    name: "Miguel",//  Júnior",
    title:"Arquitecto e Ilustrador",
    imageUri: require("../projectos/PLATAFORMA/NOS/Miguel_Junior.webp"),
    linkedin:"https://www.linkedin.com/in/miguel-junior-679b2620a/"
  },
  {
    id: "4",
    name: "Judite",//  Nguluve",
    title:"Analista Financeiro e Administrativo",
    imageUri: require("../projectos/PLATAFORMA/NOS/Judite_Jorge.webp"),
    linkedin:"https://www.linkedin.com/in/judite-jorge-nguluve-ab8324296/"
  },

  {
    id: "5",
    name: "Danilo",//  Tajú",
    title:"Estagiário de Arquitectura",
    imageUri: require("../projectos/PLATAFORMA/NOS/Danilo_Taju.webp"),
    linkedin:"https://www.linkedin.com/in/danilo-tajú-97933b215/"
  },


  {
    id: "6",
    name: "Yonss",//  José",
    title:"Webdesigner",
    imageUri: require("../projectos/PLATAFORMA/NOS/yonss.webp"),
    linkedin:"https://www.linkedin.com/in/yonss-josé-b2303017b/"
  },
  {
    id: "7",
    name: "Anderson",//  Chaúque",
    title:"Eng. Estrutura e Hidráulica",
    imageUri: require("../projectos/PLATAFORMA/NOS/Anderson_Chauque.webp"),
    linkedin:"https://www.linkedin.com/in/anderson-chaúque-21ba02154/"
  },
    //{
   // id:"8",
     // name: "",//  Bó",
     // title:"Tec. Electricidade",
      //imageUri: require(" "),
      //linkedin:""
    //},
  {
    id: "9",
    name: "Claudio",
    title:'Medidor e Orçamentista',
    imageUri: require("../projectos/portraits/NOS/Claudio_Sengue.webp"),
    linkedin:"https://www.linkedin.com/in/claudio-sengue-62338856/"
  },

  {
    id:"10",
    name: "Tavares",//  Cebola",
    title:"Consultor de Comunicação",
    imageUri: require("../projectos/PLATAFORMA/NOS/Tavares_Cebola.webp"),
    linkedin:""
  },
  {
    id: "11",
    name: "Mendes",//  Murucha",
    title:"Consultor Jurídico",
    imageUri: require("../projectos/PLATAFORMA/NOS/Mendes_Murucha.webp"),
    linkedin:"https://www.linkedin.com/in/mendes-murucha-75b50655/"
  },

  {
    id: "12",
    name: "Éric",//  Simbine",
    title:"Multimédia",
    imageUri: require("../projectos/PLATAFORMA/NOS/Eric_Simbine.webp"),
    linkedin:""
  },
  // Add more projects with different sizes
];

export default datateam;
