const datacontent = [ 
      {
       id: "01",
        name: "EO1",
        imagevideo:"https://www.youtube.com/embed/GuVUDNPdr9k",
        title: "EO1",
        },
    /* {
        id: "00",
        name: "EO0",
        imagevideo:"https://www.youtube.com/embed/RWlCpDz8cgo",
        title: "EO0",
        },*/
    {
        id: "02",
        name: "EO2",
        imagevideo:"https://www.youtube.com/embed/e0loXoBa9cs",
        title: "EO2 ",
            },
    {
        id: "03",
        name: "EO3",
        imagevideo:"https://www.youtube.com/embed/1VXoTGB_EZo",
        title: " EO3",
            },
    {
        id: "04",
        name: "EO4",
        imagevideo:"https://www.youtube.com/embed/RQuCVpwGdyM",
        title: "EO4",
            },
    {
        id: "05",
        name: "EO5",
        imagevideo:"https://www.youtube.com/embed/WAgRjs2fzLs",
        title: "EO5",
            },
    {
        id: "06",
        name: "EO6",
        imagevideo:"https://www.youtube.com/embed/OsqYz752Fww",
        title: "EO6",
            },
    {
        id: "07",
        name: "EO7",
        imagevideo:"https://www.youtube.com/embed/a6ttnB0an48",
        title: "EO7",
            },
    {
        id: "08",
        name: "EO8",
        imagevideo:"https://www.youtube.com/embed/bEBdOrM585g",
        title: "EO8",
            },
    {
        id: "09",
        name: "EO9",
        imagevideo:"https://www.youtube.com/embed/lkR9Pbogiqs",
        title: "EO9",
            },
    {
        id: "10",
        name: "E10",
        imagevideo:"https://www.youtube.com/embed/g0eAIYeP2bQ",
        title: "E10",
            },
    {
        id: "11",
        name: "E11",
        imagevideo:"https://www.youtube.com/embed/H9jGsui98vw",
        title: "E11",
            },
    {
        id: "12",
        name: "E12",
        imagevideo:"https://www.youtube.com/embed/wbT45z9E7-o",
        title: "E12",
            },
         
      ]
    export default datacontent;
    